import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { LayoutService } from 'angular-admin-lte';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnInit, AfterViewChecked {
  public customLayout!: boolean;

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {
    this.layoutService.isCustomLayout.subscribe((value) => {
      this.customLayout = value;
    });
  }

  ngAfterViewChecked(): void {
    var iconToggle = document.getElementsByClassName('sidebar-right-toggle')[0];

    var sidebar = document.getElementsByTagName('control-sidebar')[0];
    var sidebarOpen = document.getElementsByTagName('control-sidebar-open')[0];

    if (sidebar?.classList.contains('control-sidebar-open')) {
      iconToggle.classList.add('open');
    }
  }
}
