import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {} from 'googlemaps';
import { isEmpty, isNil } from 'lodash-es';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject, concat, of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { MODAL_CONFIG } from 'src/app/configs/global';
import { OrderStatusEnum, PaymentStatusEnum } from 'src/app/core/enum/orderstatus.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { GoogleService } from 'src/app/core/services/google.service';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { OrderRecheckService } from 'src/app/core/services/order-recheck.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { UtilService } from 'src/app/core/services/utils.service';
import { DropdownItemModel } from 'src/app/models/base/util.model';
import { CalculatedOrderResult, ProductCalModel, RequestCalculateOrderModel } from 'src/app/models/calculate.model';
import { CustomerViewModel } from 'src/app/models/customer.model';
import { Address } from 'src/app/models/google-address.model';
import {
  CustomerOrderGiftItemModel,
  OrderDetailModel,
  OrderProductDetailModel,
} from 'src/app/models/order-detail.model';
import { OrderRecheckModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel } from 'src/app/models/order.model';
import { ProductViewModel } from 'src/app/models/product.model';
import { CommonHelper } from 'src/app/utils/commonHelper';
import { environment } from 'src/environments/environment';

const SCRIPT_PATH =
  environment.GOOGLE_API_URL +
  'maps/api/js?key=' +
  environment.GOOGLE_API_KEY +
  '&libraries=places,localContext&v=weekly';

const OrderQuantityValidator: ValidatorFn | ValidatorFn[] = (fg: FormGroup) => {
  const productValue = fg.get('products').value;
  const isWaitingData = fg.get('isWaitingData').value;
  if (productValue) {
    const products = productValue.reduce((sum: any, current: any) => sum + current.quantity, 0);
    const quantity = fg.get('quantity').value;

    if ((products === 0 || products > quantity) && isWaitingData === false) {
      fg.get('products').setErrors({ invalid: true });
      return { invalid: true };
    } else {
      fg.get('products').setErrors(null);
      return null;
    }
  }
};

// Just for now
// const BoxCodeQuantityValidator: ValidatorFn | ValidatorFn[] = (fg: FormGroup) => {
// 	const quantityValue = fg.get('quantity').value;
// 	const boxCodeValue = fg.get('boxCode').value;

// 	if (quantityValue && boxCodeValue) {
// 		const eachBoxArr = Array.from(boxCodeValue);
// 		const accumulate = eachBoxArr.reduce((prev: any, current: any) => Number(prev) + Number(current), 0);

// 		if (accumulate < quantityValue || accumulate > (quantityValue + 1)) {
// 			fg.get('boxCode').setErrors({ 'invalid': true });
// 			return { 'invalid': true };
// 		} else {
// 			fg.get('boxCode').setErrors(null);
// 			return null;
// 		}
// 	}
// }

const WaitingDataValidator: ValidatorFn | ValidatorFn[] = (fg: FormGroup) => {
  const isWaitingData = fg.get('isWaitingData').value;
  const waitingData = fg.get('waitingData');
  waitingData.setValidators(isWaitingData === true ? Validators.required : null);
  return null;
};

@Component({
  selector: 'app-orders-new',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './orders-new.component.html',
  styleUrls: ['./orders-new.component.scss'],
})
export class OrdersNewComponent implements OnInit {
  // map here here map map
  @ViewChild('locationMap') locationMap: any;
  map: google.maps.Map;

  isCollapsed = false;
  isCollapsedOrderDetail = false;
  isCollapsedGift = false;
  isTimeRemark1 = false;
  isTimeRemark2 = false;
  isCustomerLoading = false;
  isProvinceLoading = false;
  isPartnerLoading = false;
  isPromotionLoading = false;
  isPaymentGatewayLoading = false;
  isGiftItemLoading = false;
  isBranchListLoading = false;
  isLockAddress = true;
  isLockTransportation = false;
  isCheckSameName = false;
  isWaitingForData = false;
  isOrderRecheck = false;
  isSkipCalPrice = false;
  isSubmitOrder = false;
  isManualPrice = false;
  quantity: number = 1;
  filterProductId = 0;
  filterProductText: string = '';
  orderId: string = '';
  customerId: string = '';
  addressId: number;
  customerRefId: number;
  branchId: number = 1;
  selectedCustomerName: string;
  selectedCustomerPhone: string;
  selectedPromotion: any = null;
  orderDetail: OrderDetailModel = new OrderDetailModel();
  orderCommon: OrderCommonModel = new OrderCommonModel();
  orderRecheck: OrderRecheckModel = new OrderRecheckModel();
  provinces = [];
  partners = [];
  promotions = [];
  giftList = [];
  branchList = [];
  giftDropdown = [];
  paymentGateway = [];
  quantityList = [];
  numberOfBoxList = [];
  customerAddresses = [];
  customerHistory = [];
  orderHistory = [];
  infoWindow: google.maps.InfoWindow;
  pinMarker: google.maps.Marker;
  searchBox: google.maps.places.SearchBox;
  productList: ProductViewModel[] = [];
  allProductList: ProductViewModel[] = [];
  categoryList: any[] = [];
  customerList$ = new Observable<CustomerViewModel[]>();
  searchText$ = new Subject<string>();
  selectedProducts: OrderProductDetailModel[] = Array();
  selectedGifts: CustomerOrderGiftItemModel[] = Array();
  selectedGiftValue;
  selectedCustomer = false;
  selectedIndexAddress;
  selectedAddressInModal = false;
  selectedAddressIndex = 0;
  addressEditMode = false;
  addressCreateMode = false;
  orderDate: any;
  deliveryDate: any;
  referenceImage = {};
  referenceImage$ = new BehaviorSubject('');
  referenceImageList$ = new BehaviorSubject(<string[]>[]);
  maxQuantity$ = new BehaviorSubject<number>(0);
  minQtyInput$ = new BehaviorSubject<number>(1);
  maxQtyInput$ = new BehaviorSubject<number>(1);
  orderForm: FormGroup = null;
  modalRef: BsModalRef;
  boxCountArr = [];
  modalConfigLarge = { ...MODAL_CONFIG, class: 'modal-lg' };
  replaceText = CommonHelper.replaceAll;

  customerForm = new FormGroup({
    name: new FormControl(),
    phone: new FormControl(),
    secondPhone: new FormControl(),
    customerReferId: new FormControl(),
    address: new FormControl(),
    subDistrict: new FormControl(),
    district: new FormControl(),
    provinceId: new FormControl(),
    postcode: new FormControl(),
    description: new FormControl(),
  });

  addressForm = new FormGroup({
    name: new FormControl(),
    phone: new FormControl(),
    secondPhone: new FormControl(),
    deliveryName: new FormControl(),
    deliveryPhoneNumber: new FormControl(),
    locationLink: new FormControl(),
    address: new FormControl(),
    subDistrict: new FormControl(),
    district: new FormControl(),
    provinceId: new FormControl(),
    postcode: new FormControl(),
    description: new FormControl(),
  });

  constructor(
    private customerService: CustomerService,
    private orderService: OrderService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private productService: ProductsService,
    private googleService: GoogleService,
    private messageService: InformationMessageService,
    private formBuilder: FormBuilder,
    private orderRecheckService: OrderRecheckService,
    private utilService: UtilService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.loadJsScript(this.renderer, SCRIPT_PATH, true, true);

    document.onfullscreenchange = function (event) {
      console.log(event);
      let target = event.target;
      let pacContainerElements = document.getElementsByClassName('pac-container');
      if (pacContainerElements.length > 0) {
        let pacContainer = document.getElementsByClassName('pac-container')[0];
        console.log(document.fullscreenElement);
        if (!document.fullscreenElement) {
          console.log('Exiting FULL SCREEN - moving pacContainer to body');
          document.getElementsByTagName('body')[0].appendChild(pacContainer);
        } else {
          console.log('Entering FULL SCREEN - moving pacContainer to target element');

          document.fullscreenElement.appendChild(pacContainer);
        }
      } else {
        console.log('FULL SCREEN change - no pacContainer found');
      }
    };
  }

  private removeText(str: string) {
    const removeSpace = str?.replace(/\s/g, '');
    return removeSpace?.replace(/เขต|แขวง|อำเภอ|ตำบล/, '');
  }

  private loadJsScript(renderer: Renderer2, src: string, isAsync = false, isDefer = false): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;

    if (isAsync) script.setAttribute('async', '');

    if (isDefer) script.setAttribute('defer', '');

    renderer.appendChild(this.document.head, script);
    return script;
  }

  private getQuantityList() {
    for (let i = 1; i <= 10; i++) {
      const item = new DropdownItemModel();
      item.id = i;
      item.name = i.toString();

      this.quantityList.push(item);
      this.numberOfBoxList.push(item);
    }
    this.quantity = 1;
  }

  private loadCustomer() {
    this.customerList$ = concat(
      of([]), // default items
      this.searchText$.pipe(
        distinctUntilChanged(),
        tap(() => (this.isCustomerLoading = true)),
        switchMap((term) =>
          this.customerService.filter(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isCustomerLoading = false;
            })
          )
        )
      )
    );
  }

  private loadPromotion() {
    this.isPromotionLoading = true;
    this.commonService.filterPromotion().subscribe((x) => {
      this.promotions = x;
      this.isPromotionLoading = false;

      if (this.orderId) {
        this.selectedPromotion = x.find((promotion) => promotion.id === this.orderDetail.promotionId);
        this.isSkipCalPrice = this.selectedPromotion.skipCalPrice;
        // TODO: Use skipCalPrice later when API is ready
        this.isManualPrice =
          this.selectedPromotion.id === 41 || this.selectedPromotion.id === 43 || this.selectedPromotion.id === 172;
        const maxQty = x.find((promotion) => promotion.id === this.orderDetail.promotionId)?.quantity;
        if (maxQty) {
          this.maxQuantity$.next(maxQty);
        } else {
          this.maxQuantity$.next(this.orderDetail?.quantity);
        }
      }
    });
  }

  private loadPartner() {
    this.isPartnerLoading = true;
    this.commonService.filterPartner().subscribe((x) => {
      this.partners = x;
      this.isPartnerLoading = false;
    });
  }

  private loadProvince() {
    this.isProvinceLoading = true;
    this.commonService.filterProvince().subscribe((x) => {
      this.provinces = x;
      this.isProvinceLoading = false;
    });
  }

  private loadPaymentGateway() {
    this.isPaymentGatewayLoading = true;
    this.commonService.filterPaymentGateway().subscribe((x) => {
      this.paymentGateway = x;
      this.isPaymentGatewayLoading = false;
    });
  }

  private loadGiftItem() {
    this.isGiftItemLoading = true;
    this.commonService.filterGiftItem().subscribe((x) => {
      this.giftList = x;
      this.giftDropdown = x;
      this.isGiftItemLoading = false;
    });
  }

  private loadBranch() {
    this.isBranchListLoading = true;
    this.commonService.filterBranchItem().subscribe((x) => {
      this.branchList = x;
      this.isBranchListLoading = false;
    });
  }

  private getOrderFormControlName(name: string) {
    return this.orderForm.controls[name];
  }

  private handleGoogleMap(setValueForm?: any) {
    //remove leftover class
    document.querySelectorAll('.pac-container').forEach((e) => e.remove());

    let lat = 13.7318766,
      lng = 100.4904249;

    const mapProperties = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(document.getElementById('locationMap'), mapProperties);

    let tmpLatLng = this.getOrderFormControlName('locationLink').value;
    if (tmpLatLng) {
      lat = parseFloat(tmpLatLng.split(',')[0]);
      lng = parseFloat(tmpLatLng.split(',')[1]);
      if (this.pinMarker) {
        this.pinMarker.setMap(null);
      } else {
        this.pinMarker = new google.maps.Marker();
      }
      this.pinMarker.setPosition(new google.maps.LatLng(lat, lng));
      this.pinMarker.setMap(this.map);
      this.map.setCenter(new google.maps.LatLng(lat, lng));
    }

    google.maps.event.clearListeners(this.map, 'click');
    this.map.addListener('click', (mapsMouseEvent) => this.doMap(mapsMouseEvent.latLng, setValueForm));

    // Create the search box and link it to the UI element.
    const searchInput = document.getElementById('pac-input') as HTMLInputElement;

    this.searchBox = new google.maps.places.SearchBox(searchInput);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(searchInput);

    // Bias the SearchBox results towards current map's viewport.
    google.maps.event.clearListeners(this.map, 'bounds_changed');
    this.map.addListener('bounds_changed', () => {
      this.searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    let markers: google.maps.Marker[] = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    google.maps.event.clearListeners(this.map, 'places_changed');
    this.searchBox.addListener('places_changed', () => {
      const places = this.searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry');
          return;
        }

        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        let m = new google.maps.Marker({
          map: this.map,
          icon,
          title: place.name,
          position: place.geometry.location,
        });
        markers.push(m);
        if (places.length == 1) {
          this.doMap(place.geometry.location, setValueForm, place);
        }
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      markers.forEach((marker) => {
        marker.addListener('click', (mapsMouseEvent) => this.doMap(mapsMouseEvent.latLng, setValueForm));
      });
      this.map.fitBounds(bounds);
    });
  }

  private getFormArray(controlName: string) {
    return this.orderForm.get(controlName) as FormArray;
  }

  private setFormArray(arrayModel: any, controlName: string) {
    if (arrayModel && arrayModel.length > 0) {
      const formGroup = arrayModel?.map((a) => this.formBuilder.group(a));
      const formArray = this.formBuilder.array(formGroup);
      return this.orderForm.setControl(controlName, formArray);
    } else {
      return this.orderForm.setControl(controlName, this.formBuilder.array([]));
    }
  }

  private getCustomerAddresses() {
    this.customerService.getCustomerAddresses(this.customerId).subscribe((res) => {
      console.log('getCustomerAddresses', res);
      this.customerAddresses = res;
    });
  }

  private getCustomerAddressById() {
    this.customerService.getCustomerAddressById(this.customerId, this.addressId).subscribe((res) => {
      console.log('getCustomerAddressById', res);
      this.customerRefId = res.customerReferId ?? undefined;
      this.getOrderFormControlName('locationLink').setValue(res.locationLink);
      this.getOrderFormControlName('address').setValue(res.address);
      this.getOrderFormControlName('subDistrict').setValue(res.subDistrict);
      this.getOrderFormControlName('district').setValue(res.district);
      this.getOrderFormControlName('provinceId').setValue(res.provinceId);
      this.getOrderFormControlName('zipCode').setValue(res.postcode);
      this.getOrderFormControlName('receiverName').setValue(res.deliveryName);
      this.getOrderFormControlName('deliveryPhoneNumber').setValue(res.deliveryPhoneNumber);
      this.getOrderFormControlName('deliveryRemark').setValue(res.description);
    });
  }

  private getOrderCustomerHistory(phone: string) {
    this.orderService.getOrderCustomerHistory(phone).subscribe((res) => {
      this.customerHistory = res;
    });
  }

  private getOrderHistory(id: string) {
    this.orderService.getOrderHistory(id).subscribe((res) => {
      this.orderHistory = res.sort(
        (a: any, b: any) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
      );
    });
  }

  private sendDeliveryToLine(id: string) {
    this.utilService.sendConfirmDeliveryDate(id).subscribe(
      () => {
        this.messageService.showSuccess('Send Successful');
      },
      () => {
        this.messageService.showError('Send failed');
      }
    );
  }
  private sendCard(id: string) {
    this.utilService.sendCard(id).subscribe(
      () => {
        this.messageService.showSuccess('Send Successful');
      },
      () => {
        this.messageService.showError('Send failed');
      }
    );
  }
  private callDriver(id: string) {
    this.orderService.getBookGrabOrder(id).subscribe(
      () => {
        this.messageService.showSuccess('Call Successful');
      },
      () => {
        this.messageService.showError('Call failed');
      }
    );
  }

  boxCodeValidator(event: any) {
    const pattern = /^[2|4|6|8]+(?:R)*$/; // Regex only 2,4,6,8 and repeat
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^2|4|6|8]/g, '');
    }
  }

  routeQueueValidator(event: any) {
    const pattern = /^[0-9]*$/; // Regex only 0-9
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }

  boxCount(value: any) {
    this.boxCountArr = CommonHelper.boxCountGroup(value);
  }

  get f() {
    return this.orderForm.controls;
  }

  get f_customer() {
    return this.customerForm.controls;
  }

  get f_address() {
    return this.addressForm.controls;
  }

  private async getAndRemoveUnusedCategory() {
    const catList = await this.commonService.getProductCategory();
    const result = [];
    catList.forEach((cat) => {
      if (cat.id == 0) {
        cat.name = 'All';
        result.push(cat);
      }
      const prod = this.allProductList.filter((x) => x.categories.some((c) => c.categoryId === cat.id));
      if (prod.length > 0) result.push(cat);
    });
    this.categoryList = result;
  }
  async ngOnInit() {
    this.getQuantityList();
    this.orderDetail.products = Array();
    this.orderId = this.route.snapshot.paramMap.get('id');
    const queryCheck = this.route.snapshot.queryParamMap.get('check');
    this.isOrderRecheck = !isNil(queryCheck);
    this.orderCommon = await this.commonService.getOrderCommon();

    this.loadCustomer();
    this.loadProvince();
    this.loadPartner();
    this.loadBranch();
    this.loadPaymentGateway();
    this.getAllProducts();

    if (this.orderId) {
      this.orderService.getOrderById(this.orderId).subscribe(async (res) => {
        this.orderDetail = res;
        this.selectedProducts = res.products;
        this.selectedGifts = res.customerOrderGiftItem;
        this.deliveryDate = res.deliveryDate ? new Date(res.deliveryDate) : null;
        this.orderDate = new Date(res.orderDate);
        this.customerId = res.customerId;
        this.branchId = res.branchId ?? 1;
        this.selectedCustomerName = res.customerName;
        this.selectedCustomerPhone = res.customerPhoneNumber;
        this.selectedCustomer = true;
        this.customerRefId = res.referenceId;
        this.isWaitingForData = res.isWaitingData;
        this.isLockAddress = res.orderStatus === 4 || res.orderStatus === 6 ? false : true; // 4 = ส่งแล้ว, 6 = เข้าระบบแล้ว
        this.orderRecheck = await this.orderRecheckService.bindOrderRecheck(res);
        this.loadPromotion();
        this.loadGiftItem();
        this.buildForm();
        this.setTimeRemark();
        this.boxCount(res.boxCode);

        if (this.orderDetail?.referenceInformation) {
          // Ref Image
          this.referenceImage = this.orderDetail.referenceInformation.filter((x) => x.fileReferenceTypeId === 1)[0];
          this.referenceImage$.next(
            CommonHelper.mapImageUrl(
              this.orderDetail.referenceInformation.filter((x) => x.fileReferenceTypeId === 1)[0]?.filePath
            ) ?? ''
          );

          // Payment Slip
          this.referenceImageList$.next(
            this.orderDetail.referenceInformation
              .filter((x) => x.fileReferenceTypeId !== 1)
              .map((e: any) => {
                return CommonHelper.mapImageUrl(e.filePath);
              })
          );
        }
      });
    } else {
      this.orderDetail.orderStatus = OrderStatusEnum.Prepare;
      this.orderDetail.paymentStatus = PaymentStatusEnum.Waiting;
      this.orderDetail.isUpcountry = false;
      this.orderDate = new Date();
      this.loadPromotion();
      this.loadGiftItem();
      this.buildForm();
    }
  }

  setTimeRemark() {
    const timeRemark = this.orderDetail?.timeRemark ?? 0;
    if (timeRemark === 1) {
      this.setTimeRemark1();
    } else if (timeRemark === 2) {
      this.setTimeRemark2();
    }
  }

  setDeliveryZone(isUpCountry: boolean) {
    return isUpCountry ? 'ต่างจังหวัด' : 'กรุงเทพและปริมณฑล';
  }

  setTransportationType(isUpCountry: boolean) {
    const transportationType = this.getOrderFormControlName('transportationType').value;
    if (this.isLockTransportation) {
      return isUpCountry
        ? this.orderCommon.transportationTypes.filter((x) => x.id === 2)[0].id
        : this.orderCommon.transportationTypes.filter((x) => x.id === 1)[0].id;
    } else {
      return transportationType;
    }
  }

  buildForm() {
    this.orderForm = this.formBuilder.group(
      {
        orderDate: [this.orderDetail?.orderDate, Validators.required],
        orderStatus: [this.orderDetail?.orderStatus],
        referenceId: [this.orderDetail?.referenceId, Validators.required],
        invoiceNumber: [this.orderDetail?.invoiceNumber],
        referenceNumber: [this.orderDetail?.referenceNumber],
        referenceText: [this.orderDetail?.referenceText],
        customerId: [this.orderDetail?.customerId, Validators.required],
        customerName: [this.orderDetail?.customerName, Validators.required],
        customerPhoneNumber: [this.orderDetail?.customerPhoneNumber],
        receiverName: [this.orderDetail?.receiverName],
        deliveryPhoneNumber: [this.orderDetail?.deliveryPhoneNumber],
        addressId: [this.orderDetail?.addressId],
        address: [this.orderDetail?.address],
        locationLink: [this.orderDetail?.locationLink],
        subDistrict: [this.orderDetail?.subDistrict],
        district: [this.orderDetail?.district],
        provinceId: [this.orderDetail?.provinceId ?? 10], // Default is Bangkok
        zipCode: [this.orderDetail?.zipCode],
        isUpcountry: [this.orderDetail?.isUpcountry],
        deliverRemark: [this.orderDetail?.deliverRemark],
        deliveryDate: [this.orderDetail?.deliveryDate, Validators.required],
        promotionId: [this.orderDetail?.promotionId, Validators.required],
        quantity: [this.orderDetail?.quantity, [Validators.required]],
        boxCode: [this.orderDetail?.boxCode, [Validators.required]],
        orderPrice: [this.orderDetail?.orderPrice],
        deliveryPrice: [this.orderDetail?.deliveryPrice ?? 0],
        discountPrice: [this.orderDetail?.discountPrice ?? 0],
        totalPrice: [this.orderDetail?.totalPrice ?? 0],
        products: [this.orderDetail?.products, Validators.required],
        customerOrderGiftItem: [this.orderDetail?.customerOrderGiftItem],
        secondPhone: [this.orderDetail?.secondPhone],
        transportationType: [this.orderDetail?.transportationType],
        routeName: [this.orderDetail?.routeName],
        routeQueue: [this.orderDetail?.routeQueue],
        smsRemark: [this.orderDetail?.smsRemark],
        factoryRemark: [this.orderDetail?.factoryRemark],
        deliveryRemark: [this.orderDetail?.deliveryRemark],
        paymentStatus: [this.orderDetail?.paymentStatus, Validators.required],
        paymentReference: [this.orderDetail?.paymentReference],
        timeRemark: [this.orderDetail?.timeRemark ?? 0],
        orderWording: [this.orderDetail?.orderWording],
        isWaitingData: [this.orderDetail?.isWaitingData ?? false],
        waitingData: [this.orderDetail?.waitingData],
        branchId: [this.orderDetail?.branchId, Validators.required],
      },
      {
        validators: [OrderQuantityValidator, WaitingDataValidator],
      }
    );

    if (this.orderId) {
      // Only PaymentSlip
      const referenceInformation = this.orderDetail?.referenceInformation
        ?.filter((x) => x.fileReferenceTypeId !== 1)
        .map((y) => ({ ...y, paymentDate: y.paymentDate ? new Date(y.paymentDate) : null }));
      this.setFormArray(referenceInformation, 'referenceInformation');
    } else {
      this.orderForm.setControl('referenceInformation', this.formBuilder.array([]));
    }
    this.maxQuantity$.next(this.orderDetail?.quantity ?? 0);
  }

  newReferenceInformation(): FormGroup {
    this.referenceImageList$.next([...this.referenceImageList$.value, '']);
    return this.formBuilder.group({
      id: '',
      referenceText: '',
      fileReferenceTypeId: 2, // 2 = PaymentSlip
      filePathBase64: '',
      paymentGatewayId: 1,
      paymentDate: new Date(),
      billValue: '',
    });
  }

  getSelectedProductQuantity() {
    return this.selectedProducts?.reduce((sum, current) => sum + current.quantity, 0);
  }

  getAllProducts() {
    this.productService.getAllProducts().subscribe((res) => {
      const sortByNameEN = res.sort((a: any, b: any) => a.nameEN.localeCompare(b.nameEN));
      this.allProductList = sortByNameEN.map((x: any) => Object.assign(new ProductViewModel(), x));
      this.productList = this.allProductList;
      this.getAndRemoveUnusedCategory();
    });
  }

  getProductImage(product: ProductViewModel) {
    return product.thumbnailImage?.url;
  }

  getReferences(form: any) {
    return form.controls.referenceInformation.controls;
  }

  updateDiscountPrice() {
    if (!this.isManualPrice) {
      this.getOrderFormControlName('discountPrice').setValue(
        this.getOrderFormControlName('orderPrice').value +
          this.getOrderFormControlName('deliveryPrice').value -
          this.getOrderFormControlName('totalPrice').value
      );
    }
  }

  updatePrice() {
    if (this.isManualPrice) {
      const orderPrice = this.getOrderFormControlName('orderPrice').value;
      const deliveryPrice = this.getOrderFormControlName('deliveryPrice').value;
      const discountPrice = this.getOrderFormControlName('discountPrice').value;
      return this.getOrderFormControlName('totalPrice').patchValue(orderPrice + deliveryPrice - discountPrice);
    }

    const addressId = this.getOrderFormControlName('addressId').value;
    const isUpcountry: boolean = this.getOrderFormControlName('isUpcountry').value;
    const selectedPromotion = this.selectedPromotion;
    const selectedProduct = this.selectedProducts;
    if (selectedPromotion && !this.isManualPrice) {
      const productCal: ProductCalModel[] = selectedProduct.map((e) => {
        return {
          productId: e.productId,
          quantity: e.quantity,
        };
      });

      if (!this.getOrderFormControlName('discountPrice').value) {
        this.getOrderFormControlName('discountPrice').setValue(0);
      }

      const requestCalculate: RequestCalculateOrderModel = {
        discountPrice: this.getOrderFormControlName('discountPrice').value,
        vatType: 0,
        promotionId: selectedPromotion.id,
        calculateOrderItem: {
          addressId: addressId,
          upcountry: isUpcountry,
          products: productCal,
        },
      };
      this.orderService.calculatePrice(requestCalculate).subscribe((res) => {
        const calculatedResult: CalculatedOrderResult = res;
        this.getOrderFormControlName('orderPrice').patchValue(calculatedResult.orderPrice);
        this.getOrderFormControlName('deliveryPrice').patchValue(calculatedResult.deliveryPrice);
        this.getOrderFormControlName('totalPrice').patchValue(calculatedResult.summaryPrice);
      });
    }
  }

  addProduct(product: any) {
    if (this.maxQuantity$.value > 0 && this.getSelectedProductQuantity() < this.maxQuantity$.value) {
      if (this.selectedProducts.some((x) => x.productId == product.id)) {
        ++this.selectedProducts.find((e) => e.productId == product.id).quantity;
      } else {
        this.selectedProducts.push({
          id: undefined,
          productId: product.id,
          code: product.ipCode,
          nameEN: product.nameEN,
          nameTH: product.nameTH,
          price: product.price,
          quantity: 1,
        });
        this.getOrderFormControlName('products').setValue(this.selectedProducts);
      }
      this.updatePrice();
    }
  }

  trackByFn(item: CustomerViewModel) {
    return item.id;
  }

  onSelectCustomer($event: any) {
    console.log('onSelectCustomer', $event);
    if ($event) {
      this.customerId = $event.id;
      this.addressId = $event.addressId;
      this.selectedCustomer = true;
      this.isLockTransportation = true;
      this.selectedCustomerPhone = CommonHelper.separatePhoneNumber($event.phone);
      this.getOrderFormControlName('customerId').setValue(this.customerId);
      this.getOrderFormControlName('customerPhoneNumber').setValue($event.phone);
      this.getOrderFormControlName('addressId').setValue(this.addressId);
      this.getOrderFormControlName('isUpcountry').setValue($event.isUpcountry);
      this.getOrderFormControlName('referenceId').setValue($event.customerReferId);
      this.getCustomerAddressById();
      this.getCustomerAddresses();
      this.updatePrice();
    } else {
      this.selectedCustomer = false;
      this.customerRefId = undefined;
      this.getOrderFormControlName('customerId').patchValue('');
      this.getOrderFormControlName('customerPhoneNumber').patchValue('');
      this.getOrderFormControlName('addressId').patchValue('');
      this.getOrderFormControlName('locationLink').patchValue('');
      this.getOrderFormControlName('address').patchValue('');
      this.getOrderFormControlName('subDistrict').patchValue('');
      this.getOrderFormControlName('district').patchValue('');
      this.getOrderFormControlName('provinceId').patchValue('');
      this.getOrderFormControlName('zipCode').patchValue('');
      this.getOrderFormControlName('receiverName').patchValue('');
      this.getOrderFormControlName('deliveryPhoneNumber').patchValue('');
      this.getOrderFormControlName('deliverRemark').patchValue('');
    }
  }

  onQuantityChange($event) {
    if (this.selectedPromotion == null) {
      this.selectedPromotion = this.promotions.filter((x) => x.id == 19)[0];
      this.getOrderFormControlName('promotionId').setValue(this.selectedPromotion.id);
    }

    this.quantity =
      this.selectedPromotion.quantity == null
        ? this.getOrderFormControlName('quantity').value
        : this.selectedPromotion.quantity;
    this.getOrderFormControlName('quantity').setValue(this.quantity);
    this.maxQuantity$.next(this.quantity ?? 0);
    if (this.maxQuantity$.value > 0 && this.getSelectedProductQuantity() > this.maxQuantity$.value) {
      // this.selectedProducts = [];
      this.getOrderFormControlName('orderPrice').setValue(0);
    }
  }

  onPromotionChange($event) {
    this.minQtyInput$.next($event.minQuantity);
    this.maxQtyInput$.next($event.maxQuantity);

    this.selectedPromotion = $event;
    this.quantity = $event.minQuantity;
    this.isSkipCalPrice = $event.skipCalPrice;
    this.getOrderFormControlName('quantity').setValue(this.quantity);

    // this.selectedProducts = [];
    // this.getOrderFormControlName('products').setValue(this.selectedProducts);
    this.onQuantityChange($event);

    // อิ่มเดี่ยว
    if (this.selectedPromotion.id === 18) {
      this.getOrderFormControlName('quantity').setValue(1);
      this.maxQuantity$.next(1);
    }

    // Same Day
    this.isManualPrice =
      this.selectedPromotion.id === 41 || this.selectedPromotion.id === 43 || this.selectedPromotion.id === 172;

    this.getOrderFormControlName('orderPrice').setValue(0);
    this.getOrderFormControlName('totalPrice').setValue(0);
  }

  onFilterProductChange(text: string) {
    this.filterProductText = text ? text?.toUpperCase() : '';
    this.onFilterProduct(null);
  }

  onFilterProduct(category: any) {
    if (category) this.filterProductId = category.id;

    if (this.filterProductId == 0) {
      this.productList = this.allProductList;
    } else {
      this.productList = this.allProductList.filter((x) =>
        x.categories?.some((c) => c.categoryId === this.filterProductId)
      );
    }
    if (this.filterProductText.length > 0) {
      this.productList = this.productList.filter(
        (x) =>
          x.nameEN?.toUpperCase()?.includes(this.filterProductText) ||
          x.nameTH?.toUpperCase()?.includes(this.filterProductText)
      );
    }
  }

  onDecreaseProduct(item) {
    --item.quantity;
    if (item.quantity === -1) {
      this.selectedProducts = this.selectedProducts.filter((x) => x.productId != item.productId);

      if (this.selectedProducts.length === 0) {
        this.getOrderFormControlName('products').patchValue(null);
      }
    }
    this.updatePrice();
  }
  onIncrementProduct(item) {
    if (this.maxQuantity$.value > 0 && this.getSelectedProductQuantity() < this.maxQuantity$.value) {
      ++item.quantity;
      this.updatePrice();
    }
  }

  onProductItemValueChange(item, $event) {
    let newVal = parseInt($event.target.value);
    let expectedVal = this.maxQuantity$.value - (this.getSelectedProductQuantity() - item.quantity);

    if (
      this.maxQuantity$.value >= 0 &&
      this.getSelectedProductQuantity() - item.quantity + newVal > this.maxQuantity$.value
    ) {
      item.quantity = expectedVal;
      $event.target.value = expectedVal;
    } else if (this.maxQuantity$.value >= 0) {
      item.quantity = newVal;
    }
    if (item.quantity < 0) {
      this.selectedProducts = this.selectedProducts.filter((x) => x.productId != item.productId);
      this.getOrderFormControlName('products').patchValue(null);
    }

    this.updatePrice();
  }

  setTimeRemark1() {
    if (!this.isTimeRemark1) {
      this.isTimeRemark1 = true;
      this.isTimeRemark2 = false;
      this.getOrderFormControlName('timeRemark').setValue(1);
    } else {
      this.isTimeRemark1 = false;
      this.isTimeRemark2 = false;
      this.getOrderFormControlName('timeRemark').setValue(null);
    }
  }

  setTimeRemark2() {
    if (!this.isTimeRemark2) {
      this.isTimeRemark2 = !this.isTimeRemark2;
      this.isTimeRemark1 = false;
      this.getOrderFormControlName('timeRemark').setValue(2);
    } else {
      this.isTimeRemark1 = false;
      this.isTimeRemark2 = false;
      this.getOrderFormControlName('timeRemark').setValue(null);
    }
  }

  addReference() {
    this.getFormArray('referenceInformation').push(this.newReferenceInformation());
  }
  setOrderDetail() {
    const orderDate = moment(this.getOrderFormControlName('orderDate').value).format('YYYY-MM-DDTHH:mm:ss');
    const deliveryDate = moment(this.getOrderFormControlName('deliveryDate').value).format('YYYY-MM-DDTHH:mm:ss');
    const boxCode = this.getOrderFormControlName('boxCode').value;
    const boxQuantity = !isNil(boxCode) ? Array?.from(boxCode).length : 0;
    const referenceInformation = this.getOrderFormControlName('referenceInformation').value;

    // Check if reference information is empty
    if (!isEmpty(this.referenceImage)) {
      referenceInformation.push(this.referenceImage);
    }

    const mapReferenceInformation = referenceInformation.map((x) => {
      const formattedPaymentDate = x.paymentDate ? moment(x.paymentDate).format('YYYY-MM-DDTHH:mm:ss') : undefined;
      return {
        ...x,
        ...(x.paymentDate && { paymentDate: formattedPaymentDate }),
      };
    });

    const obj: OrderDetailModel = {
      id: +this.orderId,
      orderDate: orderDate,
      orderStatus: this.getOrderFormControlName('orderStatus').value,
      referenceId: this.getOrderFormControlName('referenceId').value,
      branchId: this.getOrderFormControlName('branchId').value,
      invoiceNumber: this.getOrderFormControlName('invoiceNumber').value,
      referenceNumber: this.getOrderFormControlName('referenceNumber').value,
      referenceText: this.getOrderFormControlName('referenceText').value,
      customerId: this.getOrderFormControlName('customerId').value,
      customerName: this.getOrderFormControlName('customerName').value,
      customerPhoneNumber: this.getOrderFormControlName('customerPhoneNumber').value,
      receiverName: this.getOrderFormControlName('receiverName').value,
      deliveryPhoneNumber: this.getOrderFormControlName('deliveryPhoneNumber').value,
      addressId: this.getOrderFormControlName('addressId').value,
      address: this.getOrderFormControlName('address').value,
      locationLink: this.getOrderFormControlName('locationLink').value,
      subDistrict: this.getOrderFormControlName('subDistrict').value,
      district: this.getOrderFormControlName('district').value,
      provinceId: this.getOrderFormControlName('provinceId').value,
      zipCode: this.getOrderFormControlName('zipCode').value,
      isUpcountry: this.getOrderFormControlName('isUpcountry').value,
      deliverRemark: this.getOrderFormControlName('deliverRemark').value,
      deliveryDate: deliveryDate,
      promotionId: this.getOrderFormControlName('promotionId').value,
      quantity: this.getOrderFormControlName('quantity').value,
      boxQuantity: boxQuantity,
      boxCode: boxCode,
      orderPrice: this.getOrderFormControlName('orderPrice').value,
      deliveryPrice: this.getOrderFormControlName('deliveryPrice').value,
      discountPrice: this.getOrderFormControlName('discountPrice').value,
      totalPrice: this.getOrderFormControlName('totalPrice').value,
      products: this.selectedProducts,
      secondPhone: this.getOrderFormControlName('secondPhone').value,
      transportationType: this.getOrderFormControlName('transportationType').value,
      routeName: this.getOrderFormControlName('routeName').value,
      routeQueue: this.getOrderFormControlName('routeQueue').value,
      smsRemark: this.getOrderFormControlName('smsRemark').value,
      factoryRemark: this.getOrderFormControlName('factoryRemark').value,
      deliveryRemark: this.getOrderFormControlName('deliveryRemark').value,
      paymentStatus: this.getOrderFormControlName('paymentStatus').value,
      paymentReference: this.getOrderFormControlName('paymentReference').value,
      referenceInformation: mapReferenceInformation,
      customerOrderGiftItem: this.selectedGifts,
      timeRemark: this.getOrderFormControlName('timeRemark').value,
      orderWording: this.getOrderFormControlName('orderWording').value,
      isWaitingData: this.getOrderFormControlName('isWaitingData').value,
      waitingData: this.getOrderFormControlName('waitingData').value,
      receivedDate: undefined,
      eta: undefined,
      promotionName: '',
      remark: '',
      paymentStatusText: '',
      vatPrice: 0,
      isDataComplete: false,
      isDelivered: false,
      isNinoxOrder: false,
      isPaid: false,
    };
    return obj;
  }

  onSubmit() {
    this.updatePrice();
    this.orderForm.markAllAsTouched();
    if (this.orderForm.valid) {
      const obj = this.setOrderDetail();
      this.isSubmitOrder = true;
      if (this.orderId) {
        this.orderService
          .updateOrder(+this.orderId, obj)
          .pipe(take(1))
          .subscribe(
            (res) => {
              console.log('updateOrder response', res);
              this.messageService.showSuccess('Update successful');
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            },
            (err) => {
              console.log('updateOrder err', err);
              this.messageService.showError('Update failed');
            }
          );
      } else {
        this.orderService
          .createOrder(obj)
          .pipe(take(1))
          .subscribe(
            (res) => {
              console.log('createOrder response', res);
              this.messageService.showSuccess('Create successful');
              setTimeout(() => {
                this.router.navigate(['/order/edit', res.id]);
              }, 3000);
            },
            (err) => {
              console.log('createOrder err', err);
              this.messageService.showError('Create failed');
            }
          );
      }
    }
  }

  saveReferenceImage(base64file: string) {
    this.referenceImage = {
      id: '',
      referenceText: '',
      filePathBase64: base64file,
      fileReferenceTypeId: 1, // 1 = Ref Image
    };
  }

  clearReferenceImage() {
    this.referenceImage = {
      ...this.referenceImage,
      filePathBase64: '',
      filePath: '',
    };
  }

  saveReferenceImageList(base64file: string, index: number) {
    const referenceInformation = this.getReferences(this.orderForm);

    referenceInformation.at(index).patchValue({
      id: referenceInformation.at(index).value.id,
      referenceText: referenceInformation.at(index).value.referenceText,
      fileReferenceTypeId: referenceInformation.at(index).value.fileReferenceTypeId,
      filePathBase64: base64file,
      paymentGatewayId: referenceInformation.at(index).value.paymentGatewayId,
      paymentDate: referenceInformation.at(index).value.paymentDate,
      fileName: '',
      fileExtension: '',
      filePath: '',
      billValid: '',
      billValue: referenceInformation.at(index).value.billValue,
      fileBase64String: '',
      orderId: this.orderId,
    });
    console.log('referenceInformation', referenceInformation);
  }

  clearReferenceImageList(index: number) {
    this.orderForm.get('referenceInformation')['controls'][index].patchValue({
      filePathBase64: '',
    });
  }

  mapImageUrl(url: string) {
    return CommonHelper.mapImageUrl(url);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, MODAL_CONFIG);
  }

  openCustomerModal(template: TemplateRef<any>) {
    this.customerForm = this.formBuilder.group({
      name: ['', Validators.required],
      customerReferId: ['', Validators.required],
      customerPhoneNumber: ['', Validators.required],
      secondPhone: [''],
      deliveryPhoneNumber: ['', Validators.required],
      deliveryName: ['', Validators.required],
      locationLink: [''],
      address: ['', Validators.required],
      subDistrict: ['', Validators.required],
      district: ['', Validators.required],
      provinceId: ['', Validators.required],
      postcode: ['', Validators.required],
      description: [''],
    });
    this.setLocationLinkValidators(this.customerForm);

    this.modalRef = this.modalService.show(template, this.modalConfigLarge);
    this.handleGoogleMap(this.customerForm);
  }

  setLocationLinkValidators(formGroup: FormGroup) {
    const locationLink = formGroup.get('locationLink');
    const province = formGroup.get('provinceId');
    province.valueChanges.pipe(distinctUntilChanged()).subscribe((province) => {
      const provinceFound = this.provinces.find((p) => p.provinceID === province);
      if (provinceFound && provinceFound.upcountry === false) {
        locationLink.setValidators([Validators.required]);
      } else {
        locationLink.clearValidators();
      }
      locationLink.updateValueAndValidity();
    });
  }

  makeReceiverAsCustomer(event: any, form: any) {
    if (event.target.checked) {
      const customerName = isNil(form.name?.value) ? form.customerName.value : form.name.value;
      const customerPhoneNumber = isNil(form.phoneNumber?.value)
        ? form.customerPhoneNumber.value
        : form.phoneNumber.value;

      form.deliveryName.setValue(customerName);
      form.deliveryPhoneNumber.setValue(customerPhoneNumber);
    } else {
      form.deliveryName.setValue('');
      form.deliveryPhoneNumber.setValue('');
    }
  }

  openLocationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfigLarge);
    this.handleGoogleMap();
  }

  openAddressesModal(template: TemplateRef<any>) {
    this.buildAddressForm();
    this.customerService.getCustomerAddresses(this.customerId).subscribe((res) => {
      this.customerAddresses = res;
    });
    this.modalRef = this.modalService.show(template, this.modalConfigLarge);
  }

  cancelAddressModal() {
    this.selectedAddressInModal = false;
    this.addressCreateMode = false;
    this.addressEditMode = false;
    this.isCheckSameName = false;
    this.selectedIndexAddress = null;
    this.buildAddressForm();
    this.customerService.getCustomerAddresses(this.customerId).subscribe((res) => {
      this.customerAddresses = res;
    });
  }

  displayAddressInfo(address: any) {
    const provinceName = this.provinces.find((x) => x.provinceID === address.provinceId)?.name;
    const combinedAddress = `${this.selectedCustomerName}, ${this.selectedCustomerPhone}, ${address.address}, ${address.subDistrict}, ${address.district}, ${provinceName}, ${address.postcode}`;
    return combinedAddress;
  }

  buildAddressForm(address?: any) {
    if (address) {
      this.addressId = address.id;
      this.addressForm = this.formBuilder.group({
        customerName: [this.selectedCustomerName, Validators.required],
        phoneNumber: [this.selectedCustomerPhone, Validators.required],
        deliveryName: [address.name, Validators.required],
        deliveryPhoneNumber: [CommonHelper.separatePhoneNumber(address.deliveryPhoneNumber), Validators.required],
        locationLink: [address.locationLink, Validators.required],
        address: [address.address, Validators.required],
        subDistrict: [address.subDistrict, Validators.required],
        district: [address.district, Validators.required],
        provinceId: [address.provinceId, Validators.required],
        postcode: [address.postcode, Validators.required],
        description: [address.description],
        secondPhone: [address?.secondPhone],
      });
    } else {
      this.addressForm = this.formBuilder.group({
        customerName: [this.selectedCustomerName, Validators.required],
        phoneNumber: [this.selectedCustomerPhone, Validators.required],
        deliveryName: ['', Validators.required],
        deliveryPhoneNumber: ['', Validators.required],
        locationLink: ['', Validators.required],
        address: ['', Validators.required],
        subDistrict: ['', Validators.required],
        district: ['', Validators.required],
        provinceId: ['', Validators.required],
        postcode: ['', Validators.required],
        description: [''],
        secondPhone: [''],
      });
    }
    this.setLocationLinkValidators(this.addressForm);
  }

  doMap(latLng, setValueForm?: any, place?: google.maps.places.PlaceResult) {
    if (this.pinMarker) {
      this.pinMarker.setMap(null);
    } else {
      this.pinMarker = new google.maps.Marker();
    }
    this.pinMarker.setPosition(latLng);
    this.pinMarker.setMap(this.map);

    if (place) {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      this.infoWindow = new google.maps.InfoWindow();
      const content = document.createElement('div');
      const nameElement = document.createElement('h2');

      nameElement.textContent = place.name;
      content.appendChild(nameElement);

      const placeIdElement = document.createElement('p');

      placeIdElement.textContent = place.place_id;
      content.appendChild(placeIdElement);

      const placeAddressElement = document.createElement('p');

      placeAddressElement.textContent = place.formatted_address;
      content.appendChild(placeAddressElement);
      try {
        const placePictureElement = document.createElement('img');
        let aa = { maxWidth: 140 }; //
        let bb = place.photos[0].getUrl(aa);
        //aa.max
        console.log(bb);
        placePictureElement.src = bb;
        content.appendChild(placePictureElement);
      } catch (e) {
        console.log('error', e);
      }
      this.infoWindow.setContent(content);
      this.infoWindow.open(this.map, this.pinMarker);
    }
    const locationMap = this.pinMarker.getPosition().toString().replace('(', '').replace(')', '');

    if (setValueForm) {
      setValueForm.controls?.locationLink?.setValue(locationMap);
      this.googleService.getAddressInfo(locationMap).subscribe((res) => {
        const address: Address = res.results[0];
        const province = this.provinces.find(
          (p) =>
            p.name ===
            address.address_components.filter((x) => x.types.indexOf('administrative_area_level_1') > -1)[0]?.long_name
        );
        const addressInfo = address.address_components
          .filter((x) => x.types.indexOf('route') > -1 || x.types.indexOf('street_number') > -1)
          ?.map((x) => x.short_name)
          .join(' ');
        const subDistrict = this.removeText(
          address.address_components.filter(
            (x) => x.types.indexOf('sublocality_level_2') > -1 || x.types.indexOf('locality') > -1
          )[0]?.long_name
        );
        const district = this.removeText(
          address.address_components.filter(
            (x) => x.types.indexOf('administrative_area_level_2') > -1 || x.types.indexOf('sublocality_level_1') > -1
          )[0]?.long_name
        );
        const postcode = address.address_components.filter((x) => x.types.indexOf('postal_code') > -1)[0]?.short_name;

        setValueForm.controls.address.setValue(addressInfo);
        setValueForm.controls.district.setValue(district);
        setValueForm.controls.subDistrict.setValue(subDistrict);
        setValueForm.controls.postcode.setValue(postcode);
        setValueForm.controls.provinceId.setValue(province?.provinceID);
      });
    }
  }

  setLocationFromMap() {
    if (this.pinMarker) {
      let gps = this.pinMarker.getPosition().toString().replace('(', '').replace(')', '').replace(' ', '').trim();
      this.getOrderFormControlName('locationLink').setValue(gps);
      this.googleService.getAddressInfo(gps).subscribe((res) => {
        console.log(res.results);
      });
      this.modalRef.hide();
    }
  }

  onCustomerSubmit() {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      console.log('Customer obj on submit ', this.customerForm.value);
      this.customerService
        .createCustomerFromOrder(this.customerForm.value)
        .pipe(take(1))
        .subscribe(
          (res) => {
            this.selectedCustomerName = res.name;
            this.selectedCustomerPhone = res.phone;
            this.selectedCustomer = true;
            this.customerId = res.id;
            this.customerRefId =
              res.customerReferId === 0 ? this.customerForm.value.customerReferId : res.customerReferId;
            this.getOrderFormControlName('customerId').setValue(res.id);
            this.getOrderFormControlName('customerName').setValue(res.name);
            this.getOrderFormControlName('customerPhoneNumber').setValue(res.phone);
            this.customerService.getCustomerAddresses(res.id).subscribe((res) => {
              console.log('getCustomerAddresses response', res);
              this.customerAddresses = res;
              this.getOrderFormControlName('addressId').setValue(this.customerAddresses[0].id);
              this.bindAddressInOrder(this.customerAddresses[0]);
            });
            this.messageService.showSuccess('Create successful');
            this.modalRef.hide();
          },
          (err) => {
            this.messageService.showError('Create failed');
          }
        );
    }
  }

  onAddressModalClose() {
    this.modalRef.hide();
    this.selectedAddressInModal = false;
    this.addressEditMode = false;
    this.addressCreateMode = false;
    this.selectedAddressIndex = 0;
  }

  onChangeAddress(address: any) {
    console.log('onChangeAddress', address);
    this.getOrderFormControlName('isUpcountry').setValue(address.isUpCountry);
    this.getOrderFormControlName('locationLink').setValue(address.locationLink);
    this.getOrderFormControlName('address').setValue(address.address);
    this.getOrderFormControlName('subDistrict').setValue(address.subDistrict);
    this.getOrderFormControlName('district').setValue(address.district);
    this.getOrderFormControlName('provinceId').setValue(address.provinceId);
    this.getOrderFormControlName('zipCode').setValue(address.postcode);
    this.getOrderFormControlName('receiverName').setValue(address.name);
    this.getOrderFormControlName('deliveryPhoneNumber').setValue(address.deliveryPhoneNumber);
    this.getOrderFormControlName('deliverRemark').setValue(address.description);
    this.getOrderFormControlName('secondPhone').setValue(address.secondPhone);
    this.buildAddressForm(address);
    this.updatePrice();
    this.isLockTransportation = true;
  }

  onEditOrCreateAddress(isEdit: boolean, address?: any, index?: number) {
    this.selectedIndexAddress = index;
    this.selectedAddressInModal = true;
    this.buildAddressForm(address);
    this.handleGoogleMap(this.addressForm);

    if (isEdit) {
      this.addressEditMode = true;
      this.addressCreateMode = false;
    } else {
      this.addressCreateMode = true;
      this.addressEditMode = false;
    }
  }

  bindAddressInOrder(obj: any) {
    const isUpCountry = this.orderCommon.provinces.find((p) => p.provinceID === obj.provinceId).upcountry;
    this.getOrderFormControlName('isUpcountry').setValue(isUpCountry);
    this.getOrderFormControlName('locationLink').setValue(obj.locationLink);
    this.getOrderFormControlName('address').setValue(obj.address);
    this.getOrderFormControlName('subDistrict').setValue(obj.subDistrict);
    this.getOrderFormControlName('district').setValue(obj.district);
    this.getOrderFormControlName('provinceId').setValue(obj.provinceId);
    this.getOrderFormControlName('zipCode').setValue(obj.postcode);
    this.getOrderFormControlName('receiverName').setValue(obj.deliveryName);
    this.getOrderFormControlName('deliveryPhoneNumber').setValue(obj.deliveryPhoneNumber);
    this.getOrderFormControlName('deliveryRemark').setValue(obj.remark);
    this.getOrderFormControlName('secondPhone').setValue(obj.secondPhone);
    this.updatePrice();
  }

  onAddressSubmit() {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      console.log('address obj on submit', this.addressForm.value);
      // Create new Address
      if (this.addressCreateMode) {
        this.customerService
          .createCustomerAddress(this.customerId, this.addressForm.value)
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.messageService.showSuccess('Create successful');
              this.bindAddressInOrder(res);
              this.getCustomerAddressById();
              this.getCustomerAddresses();
              this.modalRef.hide();
            },
            (err) => {
              this.messageService.showError('Create failed');
            }
          );
      } else {
        this.customerService
          .updateCustomerAddress(this.customerId, this.addressId, this.addressForm.value)
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.messageService.showSuccess('Update successful');
              this.bindAddressInOrder(res);
              this.getCustomerAddressById();
              this.getCustomerAddresses();
              this.modalRef.hide();
            },
            (err) => {
              this.messageService.showError('Update failed');
            }
          );
      }
      this.selectedAddressInModal = false;
      this.addressCreateMode = false;
      this.addressEditMode = false;
      this.isCheckSameName = false;
      this.selectedIndexAddress = null;
    }
  }

  openConfirmDeleteModal(template: TemplateRef<any>, index: number) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.id = index;
  }

  onDeleteReference(index: number) {
    this.getFormArray('referenceInformation').removeAt(index);
  }

  getGiftName(id: number) {
    return this.giftList.find((x) => x.id === id)?.name;
  }

  onCheckDataChange($event) {
    this.isWaitingForData = $event.target.checked;
    this.getOrderFormControlName('waitingData').setValue('');
    if (!this.isWaitingForData) {
      this.getOrderFormControlName('promotionId').setValidators([Validators.required]);
      this.getOrderFormControlName('customerId').setValidators([Validators.required]);
      this.getOrderFormControlName('customerName').setValidators([Validators.required]);
      this.getOrderFormControlName('deliveryDate').setValidators([Validators.required]);
      this.getOrderFormControlName('quantity').setValidators([Validators.required]);
      this.getOrderFormControlName('boxCode').setValidators([Validators.required]);
      this.getOrderFormControlName('products').setValidators([Validators.required]);
      this.getOrderFormControlName('paymentStatus').setValidators([Validators.required]);
      this.getOrderFormControlName('referenceId').setValidators([Validators.required]);
    } else {
      this.getOrderFormControlName('promotionId').clearValidators();
      this.getOrderFormControlName('customerId').clearValidators();
      this.getOrderFormControlName('customerName').clearValidators();
      this.getOrderFormControlName('deliveryDate').clearValidators();
      this.getOrderFormControlName('quantity').clearValidators();
      this.getOrderFormControlName('boxCode').clearValidators();
      this.getOrderFormControlName('products').clearValidators();
      this.getOrderFormControlName('paymentStatus').clearValidators();
      this.getOrderFormControlName('referenceId').clearValidators();
    }
    this.getOrderFormControlName('promotionId').updateValueAndValidity();
    this.getOrderFormControlName('customerId').updateValueAndValidity();
    this.getOrderFormControlName('customerName').updateValueAndValidity();
    this.getOrderFormControlName('deliveryDate').updateValueAndValidity();
    this.getOrderFormControlName('quantity').updateValueAndValidity();
    this.getOrderFormControlName('boxCode').updateValueAndValidity();
    this.getOrderFormControlName('products').updateValueAndValidity();
    this.getOrderFormControlName('paymentStatus').updateValueAndValidity();
    this.getOrderFormControlName('referenceId').updateValueAndValidity();
  }

  onGiftOpen() {
    this.giftDropdown = this.giftDropdown.filter((g) => !this.selectedGifts.find((s: any) => s.giftItemId === g.id));
  }

  onGiftChange(gift: any) {
    this.selectedGifts.push({
      id: undefined,
      orderId: +this.orderId,
      giftItemId: gift.id,
      amount: 1,
    });
    setTimeout(() => {
      this.selectedGiftValue = null;
    });
  }

  onDecreaseGift(item) {
    --item.amount;
    if (item.amount === 0) {
      this.selectedGifts = this.selectedGifts.filter((x) => x.giftItemId != item.giftItemId);
      this.loadGiftItem();
    }
  }

  onIncrementGift(item) {
    ++item.amount;
  }

  openCustomerHistoryModal(template: TemplateRef<any>) {
    this.getOrderCustomerHistory(this.selectedCustomerPhone);
    this.modalRef = this.modalService.show(template, this.modalConfigLarge);
  }

  openOrderHistoryModal(template: TemplateRef<any>) {
    this.getOrderHistory(this.orderId);
    this.modalRef = this.modalService.show(template, this.modalConfigLarge);
  }

  convertDateToString(date: any, includeTime: boolean = false) {
    const format = includeTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY';
    return date == null ? '-' : moment(date).format(format);
  }

  previousPage() {
    this.router.navigateByUrl(`/order/edit/${this.orderDetail.previousOrderId}?check=true`).then(() => {
      window.location.reload();
    });
  }

  nextPage() {
    this.router.navigateByUrl(`/order/edit/${this.orderDetail.nextOrderId}?check=true`).then(() => {
      window.location.reload();
    });
  }

  onAction(value: string) {
    switch (value) {
      case 'line':
        this.sendDeliveryToLine(this.orderId);
        break;
      case 'card':
        this.sendCard(this.orderId);
        break;
      case 'call':
        this.callDriver(this.orderId);
        break;
      default:
        break;
    }
  }

  openReturnOrderDialog(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onReturnOrder() {
    this.modalRef.hide();
    this.orderService.returnOrderRequest(this.orderId).subscribe(
      () => {
        this.messageService.showSuccess('Return Successful');
      },
      () => {
        this.messageService.showError('Return failed');
      }
    );
  }
}
