import { ThrowStmt } from '@angular/compiler';
import { ANALYZE_FOR_ENTRY_COMPONENTS, Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { IMAGE_URL } from 'src/app/configs/global';
import { ListOrderService } from 'src/app/core/services/list-order.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ListPageModel } from 'src/app/models/base/paging.model';
import { OrderFilterModel } from 'src/app/models/order-filter.model';
import { OrderDetail, OrderListItem, OrderPartnerItemsModel } from 'src/app/models/order.model';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  orderListPaging: ListPageModel;
  orderList: OrderListItem[] = [];
  formDate: Date;
  toDate: Date;
  searchText = '';
  filterModel: OrderFilterModel;
  filter$: Subscription;
  list$: Subscription;
  dateRange: any[] = Array();

  modalImageUrl: string = '';

  modalRef!: BsModalRef;
  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
    size: 'lg',
  };

  constructor(
    private orderService: OrderService,
    private listOrderService: ListOrderService,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.filter$ = this.listOrderService.filter.subscribe((o) => {
      this.filterModel = o;
      this.dateRange = [moment(o.start).toDate(), moment(o.stop).toDate()];
      this.listOrderService.getOrderList();
    });

    this.list$ = this.listOrderService.list.subscribe((o: any) => {
      this.orderList = o;
    });
  }

  ngOnDestroy() {
    if (this.filter$) this.filter$.unsubscribe();

    if (this.list$) this.list$.unsubscribe();
  }

  dateRangeChange($event: any) {
    this.listOrderService.searchByRange($event[0], $event[1]);
  }

  convertDateToString(date: any) {
    return date == null ? '-' : moment(date).format('DD/MM/YYYY');
  }

  rowClick(order: any) {
    this.router.navigate(['orders', order.id]);
  }

  orderFilter(event: any) {
    this.listOrderService.getOrderList();
  }

  clearFilter() {
    this.listOrderService.clearFilter();
  }

  showImageModel(template: TemplateRef<any>, url: string) {
    if (url) {
      this.modalImageUrl = IMAGE_URL + '/' + url;
      this.modalRef = this.modalService.show(template, this.modalConfig);
    }
  }
}
